import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/main',
    children: [
      {
        path: '/dashboard',
        name: 'Заявки',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/event/:id',
        name: 'Вход',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      },
      {
        path: '/changepassword',
        name: 'ChangePassword',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/ChangePassword.vue'),
      },
      {
        path: '/createrequest/:id',
        name: 'Новая заявка',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/CreateRequest.vue'),
      },
      {
        path: '/viewrequest/:id',
        name: 'Новое мероприятие',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/RequestView.vue'),
      },
      {
        path: '/viewparticpants',
        name: 'Посетители',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/ParticpantsView.vue'),
      },
      {
        path: '/main',
        name: 'Главная',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/MainPage.vue'),
      },
      {
        path: '/quotas',
        name: 'Квоты',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/Quotas.vue'),
      },
      {
        path: '/ts',
        name: 'ТС',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/TS.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
