<template>
  <div>
    <AppSidebar v-if="apikey != undefined" />
    <div
      class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
    >
      <AppHeader v-if="apikey != undefined" />
      <CContainer v-if="apikey != undefined" fluid>
        <CBreadcrumb>
          <CBreadcrumbItem v-if="$route.name !== 'Главная'" href="#/main"
            >Главная</CBreadcrumbItem
          >
          <CBreadcrumbItem active>{{ $route.name }}</CBreadcrumbItem>
        </CBreadcrumb>
      </CContainer>
      <div class="body flex-grow-1">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default {
  name: 'DefaultLayout',
  data() {
    return {
      apikey: this.$cookies.get('apikey'),
    }
  },
  mounted() {
    console.log(this.$cookies.get('apikey'))
  },
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
}
</script>
