export default [
  {
    component: 'CNavItem',
    name: 'Главная',
    to: '/main',
    icon: 'cil-home',
  },
  {
    component: 'CNavItem',
    name: 'Квоты',
    to: '/quotas',
    icon: 'cil-quota',
  },
  {
    component: 'CNavItem',
    name: 'Заявки',
    to: '/dashboard',
    icon: 'cil-request',
  },
  {
    component: 'CNavItem',
    name: 'Посетители',
    to: '/viewparticpants',
    icon: 'cil-visitor',
  },
  {
    component: 'CNavItem',
    name: 'ТС',
    to: '/ts',
    icon: 'cil-visitor',
  },
]
