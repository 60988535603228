const configs = {
  API: 'http://localhost:11018/',
  //API: 'https://api2.ruvents.dev/',
  //API: 'https://contractors.accred.ruvents.dev/',
  Partner: 'https://partner.ruvents.dev/',
  AnketaURL: 'https://bid.ruvents.dev/',
  Storage: 'https://accred.crki.art/storage/',
  Title: 'Тестовый сервер. Контракторс',
  isTavrida: false,
}
export default configs
